.pic-container {
    position: relative;
    width: 50%;
    #skills-container {
        position: relative;
        display: flex;
        width: 50%;
    }
    #my-image {
        width: 100%;
        transition: 1s;

        &:hover {
            cursor: pointer;
            width: 110%;
        }

        &:hover ~ #skill1 {
            left: -30%;
            top: -1%;
        }

        &:hover ~ #skill2 {
            left: -30%;
            top: 85%;
        }

        &:hover ~ #skill3 {
            left: 118%;
            top: -1%;
        }

        &:hover ~ #skill4 {
            left: 118%;
            top: 85%;
        }

        &:hover ~ #skill5 {
            left: -30%;
            top: 40%;
        }

        &:hover ~ #skill6 {
            left: 118%;
            top: 40%;
        }
    }

    .skill-icon {
        position: absolute;
        width: 60px;
        z-index: -1;
        transition: all 1s;

        @media(min-width: 800px) and (max-width: 900px) {
            width: 30px;
        }
    }

    #skill1 {
        top: 2%;
        left: 0;
    }

    #skill2 {
        top: 80%;
        left: 0;
    }

    #skill3 {
        top: 2%;
        left: 75%;
    }

    #skill4 {
        top: 80%;
        left: 75%;
    }

    #skill5 {
        top: 40%;
        left: 0;
    }

    #skill6 {
        top: 40%;
        left: 75%;
    }
}